import { useGetMergeConnections } from '@src/api/integrations'
import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { Text, Avatar, Spacer, Token, HStack, VStack, Box } from '@revolut/ui-kit'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { DisconnectMergeIntegrationItem } from '@src/pages/Hub/Integrations/components/DisconnectMergeIntegrationItem'
import { ConnectMergeIntegrationButton } from '@src/pages/Hub/Integrations/components/ConnectMergeIntegrationButton'
import { MergeDisclaimer } from '@src/pages/Hub/Integrations/components/MergeDisclaimer'
import { SyncBanner } from '@src/pages/Hub/Integrations/components/SyncBanner'

export const HiBobIntegration = () => {
  const { data: integrations, refetch, isLoading } = useGetMergeConnections()

  const integration = integrations?.results?.find(int => int.integration_type === 'hibob')
  const isConnected = Boolean(integration)

  return (
    <PageWrapper>
      <PageHeader
        title={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }}>
            <HStack align="start">
              <VStack>
                <Text>HiBob</Text>
                {isConnected && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  HRIS integrations
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image="https://assets.revolut.com/revoluters/hub-icons/hibob-40@2x.png"
                size={56}
              >
                {isConnected ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
      />

      {!isLoading && !isConnected && (
        <Box>
          <ConnectMergeIntegrationButton
            integration="hibob"
            onSuccess={() => refetch()}
            useIcon="Link"
            variant="accent"
          >
            Enable
          </ConnectMergeIntegrationButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <SyncBanner integration={integration} />
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.hibob.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.hibob.intro.text"
                defaultMessage="Easily import employees information from HiBob"
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.hibob.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.hibob.intro.tips.integrate.title"
                  defaultMessage="Connect your HiBob account"
                />
              ),
              icon: 'Link',
              text: (
                <FormattedMessage
                  id="integrations.hibob.intro.tips.integrate.text"
                  defaultMessage="You will be asked to specify your HiBob login information in this step. Please note, you have to be HiBob admin in order to connect to Revolut People."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.hibob.intro.tips.mapping.title"
                  defaultMessage="Review field mapping"
                />
              ),
              icon: 'Gear',
              text: (
                <FormattedMessage
                  id="integrations.hibob.intro.tips.mapping.text"
                  defaultMessage="After connecting to HiBob, review field mapping between HiBob and Revolut People. We will map all required fields automatically. You can map any additional fields during integration process or later in Employee Settings."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.hibob.intro.tips.sync.title"
                  defaultMessage="Sync employees"
                />
              ),
              icon: 'ArrowExchange',
              text: (
                <FormattedMessage
                  id="integrations.hibob.intro.tips.sync.text"
                  defaultMessage="By default your employees will sync with Revolut People automatically daily. You can switch between auto-sync and manual sync in Employee Settings."
                />
              ),
            },
          ]}
        />
        {!isLoading && !isConnected ? <MergeDisclaimer /> : null}

        {isConnected && !!integration && (
          <DisconnectMergeIntegrationItem
            title="HiBob"
            integrationId={integration.id}
            onSuccess={() => refetch()}
          />
        )}
      </PageBody>
    </PageWrapper>
  )
}
