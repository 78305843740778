import React from 'react'
import { HStack, Badge, Text, Spinner, Icon, Token } from '@revolut/ui-kit'
import { AiIcon } from './AiIcon/AiIcon'

interface SectionStatusProps {
  title: string
  isGeneratedByAi?: boolean
  isEdited?: boolean
  isLoading?: boolean
}

const SectionStatus = ({
  title,
  isGeneratedByAi = false,
  isEdited = false,
  isLoading = false,
}: SectionStatusProps) => {
  return (
    <HStack gap="s-6" align="center">
      <Text variant="emphasis1" color={Token.color.foreground}>
        {title}
      </Text>

      {isGeneratedByAi && <AiIcon />}

      {(isEdited || isLoading) && (
        <Badge
          backgroundColor={Token.color.widgetBackground}
          color={Token.color.foreground}
        >
          <HStack gap="s-2" align="center" pr="s-4">
            {isLoading ? <Spinner size={16} /> : <Icon name="Check" size={16} />}
            {isLoading ? 'Updating' : 'Updated'}
          </HStack>
        </Badge>
      )}
    </HStack>
  )
}

export default SectionStatus
