import { ActionButton, ActionButtonProps, Button, StatusPopup } from '@revolut/ui-kit'
import React, { useCallback, useEffect, MouseEvent } from 'react'
import {
  useCreateMergeConnection,
  useActivateMergeConnection,
  useGetMergeConnections,
  useDeleteMergeConnection,
} from '@src/api/integrations'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { MergeIntegration } from '@src/interfaces/integrations'

const MERGE_API_URL = 'https://api-eu.merge.dev'

interface ConnectMergeIntegrationButtonProps extends ActionButtonProps {
  integration: MergeIntegration
  onSuccess?: () => void
  onFailure?: () => void
  askConsent?: boolean
}

const integrationNameMap: Record<MergeIntegration, string> = {
  bamboohr: 'BambooHR',
  hibob: 'HiBob',
}

export const ConnectMergeIntegrationButton = ({
  integration,
  onSuccess,
  onFailure,
  onClick,
  askConsent,
  ...rest
}: ConnectMergeIntegrationButtonProps) => {
  const { data: integrations, isLoading } = useGetMergeConnections()
  const showStatusPopup = useShowStatusPopup()
  const {
    mutateAsync: createConnection,
    data,
    isLoading: isCreatingConnection,
    isSuccess,
  } = useCreateMergeConnection()
  const { mutateAsync: activateConnection } = useActivateMergeConnection(data?.data.id)
  const existingIntegration = integrations?.results?.find(
    int => int.integration_type !== integration,
  )
  const { mutateAsync: deleteConnection } = useDeleteMergeConnection()

  const onConnectSuccess = useCallback(
    async public_token => {
      try {
        await activateConnection([undefined, { public_token }])
        onSuccess?.()
      } catch (err) {
        onFailure?.()
        showStatusPopup({
          status: 'error',
          title: 'Failed to activate connection',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      }
    },
    [onSuccess, showStatusPopup],
  )

  const { open, isReady } = useMergeLink({
    linkToken: data?.data.token,
    onSuccess: onConnectSuccess,
    tenantConfig: {
      apiBaseURL: MERGE_API_URL,
    },
  })

  useEffect(() => {
    if (isSuccess && isReady) {
      open()
    }
  }, [isSuccess, isReady])

  const onConnect = async () => {
    try {
      await createConnection({ integration_type: integration })
    } catch (err) {
      onFailure?.()
      showStatusPopup({
        status: 'error',
        title: 'Failed to enable',
        description: getStringMessageFromError(
          err,
          'Something went wrong. Please try again.',
        ),
      })
    }
  }

  const changeIntegration = async () => {
    try {
      if (existingIntegration?.id) {
        showStatusPopup({
          title: `Disabling existing ${
            integrationNameMap[existingIntegration.integration_type]
          } integration`,
          status: 'pending',
        })
        await deleteConnection(existingIntegration.id)
      }

      onConnect()
    } catch (error) {
      showStatusPopup({
        title: 'Cannot disable integration',
        description: getStringMessageFromError(error),
        status: 'error',
      })
    }
  }

  const onDisconnect = async () => {
    showStatusPopup({
      status: 'warning',
      title: 'Change employee source',
      description: (
        <>
          This action will disable your existing integration.
          <br />
          <br />
          Are you sure you want to continue?
        </>
      ),
      actions: statusPopup => (
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
          <Button elevated onClick={changeIntegration}>
            Continue
          </Button>
        </StatusPopup.Actions>
      ),
    })
  }

  const showConsentPopup = async () => {
    if (!askConsent) {
      existingIntegration ? onDisconnect() : onConnect()
      return
    }

    showStatusPopup({
      status: 'warning',
      title: `Connect to ${integrationNameMap[integration]}`,
      description: `By clicking Continue, you agree that Merge API, Inc. will access personal data from ${integrationNameMap[integration]} as our sub-processor`,
      actions: statusPopup => (
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              statusPopup.hide()
              existingIntegration ? onDisconnect() : onConnect()
            }}
          >
            Continue
          </Button>
        </StatusPopup.Actions>
      ),
    })
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClick?.(e)
    showConsentPopup()
  }

  return (
    <ActionButton
      pending={isCreatingConnection}
      disabled={isCreatingConnection || isLoading}
      onClick={handleClick}
      {...rest}
    />
  )
}
