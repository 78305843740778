import React from 'react'
import { ActionButton, Placeholder, Widget } from '@revolut/ui-kit'
import { useCanCreateGrowthPlans } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { EmployeeInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  employee: EmployeeInterface
}

export const EmptyGrowthPlan = ({ employee }: Props) => {
  const canCreateGrowthPlans = useCanCreateGrowthPlans(employee)

  const handleAddGrowthPlan = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.GROWTH_PLAN.CREATE, { employeeId: employee.id }))
  }

  return (
    <Widget>
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-business/3D373.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-business/3D373@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-business/3D373@3x.png',
          }}
        />
        <Placeholder.Title>No growth plan yet</Placeholder.Title>
        {canCreateGrowthPlans && (
          <Placeholder.Actions>
            <ActionButton useIcon="Plus" onClick={handleAddGrowthPlan}>
              Add growth plan
            </ActionButton>
          </Placeholder.Actions>
        )}
      </Placeholder>
    </Widget>
  )
}
