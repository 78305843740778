import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  GrowthPlanInterface,
  GrowthPlanRelationInterface,
} from '@src/interfaces/growthPlans'
import { API } from '@src/constants/api'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'

export const getGrowthPlansRelations = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<GetRequestInterface<GrowthPlanRelationInterface>>(API.GROWTH_PLANS_RELATIONS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

// can be used for both filtering and sorting
export enum GrowthPlansRelationsQueryKeys {
  Id = 'id',
  LineManagerId = 'line_manager__id',
  FullName = 'full_name',
  LatestReviewScore = 'latest_review_score',
  LatestReviewScoreLabel = 'latest_review_score_label',
  TargetSpecialisationId = 'target_specialisation__id',
  TargetSeniorityId = 'target_seniority__id',
  OwnerId = 'owner_id',
  Status = 'status',
}

export const growthPlanFormRequests: RequestInterfaceNew<GrowthPlanInterface> = {
  delete: async ({ id }) => apiWithoutHandling.delete(`${API.GROWTH_PLANS}/${id}`),
  get: async ({ id }) => api.get(`${API.GROWTH_PLANS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.GROWTH_PLANS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.GROWTH_PLANS, data),
}

export const useGetGrowthPlanRelation = (employeeId: number) =>
  useFetch<GrowthPlanRelationInterface>({
    url: `${API.GROWTH_PLANS_RELATIONS}/${employeeId}`,
    withoutHandling: true,
  })

export const useGetGrowthPlan = (id?: number) =>
  useFetch<GrowthPlanInterface>({
    url: `${API.GROWTH_PLANS}/${id}`,
    withoutHandling: true,
    queryOptions: {
      enabled: Number.isInteger(id),
    },
  })
