import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Details,
  HStack,
  Widget,
  Text,
  Icon,
  Token,
  VStack,
  TextButton,
  Flex,
  Copyable,
} from '@revolut/ui-kit'
import { generateRandomId } from '@src/utils/numbers'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'
import { ActionItem } from '@src/interfaces/meetings'
import { debounce } from 'lodash'
import { Actionable } from './Actionable'
import SectionStatus from './SectionStatus'

interface Props {
  actionPoints: ActionItem[]
  isUpdating: boolean
  onChange: (value: ActionItem[]) => void
  isEdited: boolean
  isGeneratedByAi: boolean
}

export const ActionPoints = ({
  actionPoints,
  onChange,
  isUpdating,
  isEdited,
  isGeneratedByAi,
}: Props) => {
  const [isTyping, setIsTyping] = useState(false)
  const [actionItems, setActionItems] = useState<(ActionItem & { id: number })[]>(
    actionPoints.length
      ? actionPoints.map((item, index) => ({
          completed: item.completed,
          id: index,
          value: item.value,
        }))
      : [{ id: 1, value: '', completed: false }],
  )

  const textAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({})

  const toggleComplete = (id: number) => {
    setActionItems(prev =>
      prev.map(item => {
        if (item.id === id) {
          item.completed = !item.completed
        }
        return item
      }),
    )
  }

  const updateContent = (id: number, newContent: string) => {
    setActionItems(prev => {
      if (!newContent) {
        const newActionItems = prev.filter(item => item.id !== id)
        return newActionItems.length
          ? newActionItems
          : [{ id: generateRandomId(), value: '', completed: false }]
      }
      return prev.map(item => {
        if (item.id === id) {
          item.value = newContent
        }
        return item
      })
    })
  }

  const addNew = () => {
    const newId = actionItems.length
    setActionItems(prev => [...prev, { id: newId, value: '', completed: false }])
    setTimeout(() => {
      textAreaRefs.current[newId]?.focus()
    }, 0)
  }

  const updateDebounced = useCallback(
    debounce((newItems: ActionItem[]) => {
      onChange(newItems)
    }, 1000),
    [],
  )

  useEffect(() => {
    if (!isUpdating) {
      setIsTyping(false)
    }
  }, [isUpdating])

  useEffect(() => {
    updateDebounced(
      actionItems.map(item => ({ completed: item.completed, value: item.value })),
    )
  }, [actionItems])

  const isLoading = isUpdating || isTyping

  return (
    <Widget py="s-12" px="s-16" height="fit-content">
      <Details style={{ paddingTop: 0 }}>
        <Details.Title>
          <SectionStatus
            title="Actions"
            isGeneratedByAi={isGeneratedByAi}
            isEdited={isEdited}
            isLoading={isLoading}
          />
        </Details.Title>
        <Details.Content>
          <Flex alignItems="center" justifyContent="center" height="100%">
            <Copyable
              style={{ color: Token.color.greyTone20 }}
              value={actionItems.map(({ value }) => value).join(' \n')}
              labelButtonCopy="Copy"
              onCopy={() => {
                pushNotification({
                  value: 'Action points are copied to clipboard',
                  duration: SUCCESS_DEFAULT_DURATION,
                  type: NotificationTypes.success,
                })
              }}
            />
          </Flex>
        </Details.Content>
      </Details>
      <VStack gap="s-4">
        {actionItems.map(actionItem => (
          <Actionable
            actionItem={actionItem}
            key={actionItem.id}
            onEnterPressed={addNew}
            onTextChange={newValue => {
              setIsTyping(true)
              updateContent(actionItem.id, newValue)
            }}
            onChecked={() => {
              toggleComplete(actionItem.id)
            }}
            inputRef={el => {
              textAreaRefs.current[actionItem.id] = el
            }}
          />
        ))}
        <Flex justifyContent="end" width="100%">
          <TextButton onClick={addNew}>
            <HStack gap="s-4" align="center">
              <Icon name="Plus" size={16} />
              <Text variant="caption">Add action point</Text>
            </HStack>
          </TextButton>
        </Flex>
      </VStack>
    </Widget>
  )
}
