import { Dropdown, Icon, Spinner, TextButton } from '@revolut/ui-kit'
import React, { useRef } from 'react'

type Props = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  loading?: boolean
  children: React.ReactNode
  compact?: boolean
}

const StagesTableActions = ({
  isOpen,
  setOpen,
  loading = false,
  children,
  compact,
}: Props) => {
  const ref = useRef(null)

  return (
    <>
      <TextButton
        ref={ref}
        onClick={e => {
          e.stopPropagation()
          setOpen(!isOpen)
        }}
        data-testid="table-action-btn"
      >
        {loading ? <Spinner /> : <Icon name="Ellipsis" size={compact ? 18 : undefined} />}
      </TextButton>
      <Dropdown open={isOpen} anchorRef={ref} onClose={() => setOpen(false)}>
        {children}
      </Dropdown>
    </>
  )
}

export default StagesTableActions
