import React, { useEffect, useState } from 'react'
import { Flex, Group, Text, VStack } from '@revolut/ui-kit'
import { BulletPointTextArea } from './BulletPointTextArea'
import SectionStatus from './SectionStatus'

interface Props {
  notes: string
  isEnabledAI: boolean
  isLoading: boolean
  onPersonalNotesChange: (value: string) => void
  id: number | undefined
}

export const PersonalNotesSection = ({
  notes,
  isEnabledAI,
  isLoading,
  id,
  onPersonalNotesChange,
}: Props) => {
  const [isEdited, setIsEdited] = useState(false)

  useEffect(() => {
    setIsEdited(false)
  }, [id])

  return (
    <Group px="s-16" py="s-12">
      <VStack gap="s-6">
        <Flex justifyContent="space-between" alignItems="center">
          <SectionStatus
            title="Personal Notes"
            isGeneratedByAi={isEnabledAI}
            isEdited={isEdited}
            isLoading={isLoading}
          />
          <Text variant="emphasis2">Only visible to you</Text>
        </Flex>
        <BulletPointTextArea
          value={notes || ''}
          onChange={(value: string) => {
            setIsEdited(value !== notes)
            onPersonalNotesChange(value)
          }}
        />
      </VStack>
    </Group>
  )
}
