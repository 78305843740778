import { TextArea } from '@revolut/ui-kit'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { debounce, throttle } from 'lodash'
import styled from 'styled-components'

interface Props {
  value?: string
  onChange: (value: string) => void
}

const THROTTLE_TIME = 300
const DEBOUNCE_TIME = 2500
const BULLET = '•'
const BULLET_SPACE = 2

const StyledTextArea = styled(TextArea)`
  & > div {
    background-color: transparent;
    padding: 10px 16px 0 16px;
  }
`

export const BulletPointTextArea = ({ value = '', onChange }: Props) => {
  const [displayText, setDisplayText] = useState('')
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const bulletText = value
      .replace(/\\n/g, '\n')
      .split('\n')
      .map(line => (line.trim() ? `${BULLET} ${line.trim()}` : ''))
      .join('\n')
    setDisplayText(bulletText)
  }, [value])

  const debouncedAndThrottledOnChange = useCallback(
    debounce(throttle(onChange, THROTTLE_TIME), DEBOUNCE_TIME),
    [onChange],
  )

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { selectionStart } = e.target

    const lines = e.target.value.split('\n')

    const formattedDisplayText = lines
      .map(line =>
        line.startsWith(BULLET) || line.trim() === '' ? line : `${BULLET} ${line}`,
      )
      .join('\n')

    const rawText = lines
      .map(line => (line.startsWith(BULLET) ? line.slice(2) : line))
      .join('\n')

    const adjustment = formattedDisplayText.length - e.target.value.length

    setDisplayText(formattedDisplayText)

    // Used setTimeout to adjust cursor position after re-render
    setTimeout(() => {
      if (textAreaRef.current) {
        const newCursorPosition = selectionStart + adjustment
        textAreaRef.current.selectionStart = newCursorPosition
        textAreaRef.current.selectionEnd = newCursorPosition
      }
    }, 0)

    debouncedAndThrottledOnChange(rawText)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      const { selectionStart, selectionEnd } = e.currentTarget

      e.preventDefault()

      const beforeCursor = displayText.slice(0, selectionStart)
      const afterCursor = displayText.slice(selectionEnd)

      const newText = `${beforeCursor}\n${BULLET} ${afterCursor}`

      setDisplayText(newText)

      // Used setTimeout to adjust cursor position after re-render
      setTimeout(() => {
        if (textAreaRef.current) {
          const newCursorPosition = selectionStart + BULLET.length + BULLET_SPACE
          textAreaRef.current.selectionStart = newCursorPosition
          textAreaRef.current.selectionEnd = newCursorPosition
        }
      }, 0)
    }
  }

  return (
    <StyledTextArea
      ref={textAreaRef}
      rows={10}
      cols={50}
      value={displayText}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}
